// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import Step1CreditLimitIncreaseComponent from '../components/step1/step-1-credit-limit-increase.component';
import Step2CreditLimitIncreaseComponent from '../components/step2/step-2-credit-limit-increase.component';
import Step3CreditLimitIncreaseComponent from '../components/step3/step-3-credit-limit-increase.component';
import { Step4CreditLimitIncreaseComponent } from '../components/step4/step-4-credit-limit-increase.component';
import Step5CreditLimitIncreaseComponent from '../components/step5/step-5-credit-limit-increase.component';
import Step6CreditLimitIncreaseComponent from '../components/step6/step-6-credit-limit-increase.component';
import TaxSuccessComponent from '../components/tax-success/tax-success.component';
// Configurations
import { getStepConfiguration } from './credit-limit-increase-step.configuration';
// Constants
import constants from 'containers/financing/views/credit-limit-increase/constants/credit-limit-increase.constants';
// Translations
import { ERROR_EXIT_LINK } from 'translations/common.translations';
import { translationsFinalFeedback } from '../translations/final-feedback.translations';
import { titleTranslations } from './translations/credit-limit-increase.configuration.translations';
// Types
import { CreditLimitIncreaseConfigurationPropsType } from './types/credit-limit-increase.configuration.type';
import { GetFetchErrorConfigurationType } from './types/get-fetch-error.configuration.type';
import { WizardComponentPropsType } from '../types/credit-limit-increase-props.type';
// Utils
import { navigateToFinancing } from '../utils/navigate-to-financing.utils';

const getCreditLimitIncreaseConfiguration = ({
  isSuccessTax,
  defaultResult,
  creditLimitIncreaseContext,
  onCancelHybridFlow,
  navigate,
  handleGoToFinancingTracking,
}: CreditLimitIncreaseConfigurationPropsType): WizardComponentPropsType => {
  const { step1, step2, step3, step4, step5, step6, wizardStatus } = creditLimitIncreaseContext;
  const { currentStep } = wizardStatus;
  const title = titleTranslations[wizardStatus.currentStepInText];
  const onClickGoToFinancing = () =>
    navigateToFinancing({
      onCancelHybridFlow,
      navigate,
      tracking: handleGoToFinancingTracking,
    });

  return {
    feedbackConfiguration: {
      defaultResult,
      errorConfiguration: {
        title: translationsFinalFeedback.ERROR_TITLE,
        description: translationsFinalFeedback.ERROR_SUBTITLE,
        buttonLabelId: translationsFinalFeedback.GO_TO_FINANCING,
        hasDefaultLeftLink: false,
        buttonClick: onClickGoToFinancing,
      },
      successConfiguration: {
        title: translationsFinalFeedback.SUCCESS_TITLE,
        description: translationsFinalFeedback.SUCCESS_SUBTITLE,
        linkLabel: translationsFinalFeedback.GO_TO_FINANCING,
        linkClick: onClickGoToFinancing,
        content: isSuccessTax ? TaxSuccessComponent : undefined,
      },
    },
    steps: [
      {
        ...getStepConfiguration({
          title,
          currentStep,
          step: step1,
          component: Step1CreditLimitIncreaseComponent,
        }),
      },
      {
        ...getStepConfiguration({
          title,
          currentStep,
          step: step2,
          component: Step2CreditLimitIncreaseComponent,
        }),
      },
      {
        ...getStepConfiguration({
          title,
          currentStep,
          step: step3,
          component: Step3CreditLimitIncreaseComponent,
        }),
      },
      {
        ...getStepConfiguration({
          title,
          currentStep,
          step: step4,
          component: Step4CreditLimitIncreaseComponent,
        }),
      },
      {
        ...getStepConfiguration({
          title,
          currentStep,
          step: step5,
          component: Step5CreditLimitIncreaseComponent,
        }),
      },
      {
        ...getStepConfiguration({
          title,
          currentStep,
          step: step6,
          component: Step6CreditLimitIncreaseComponent,
        }),
      },
    ],
    testId: constants.TEST_ID,
    title: titleTranslations[wizardStatus.currentStepInText],
  };
};

const getFetchErrorConfiguration = ({
  formatMessage,
  onCancelHybridFlow,
  navigate,
}: GetFetchErrorConfigurationType): React.ComponentProps<typeof FetchErrorComponent> => ({
  children: formatMessage({ id: ERROR_EXIT_LINK }),
  onClick: () => navigateToFinancing({ onCancelHybridFlow, navigate }),
});

export { getCreditLimitIncreaseConfiguration, getFetchErrorConfiguration };
