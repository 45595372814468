// Vendors
import React from 'react';
// Constants
import constants, {
  cancelUrl,
} from 'containers/financing/views/credit-limit-increase/constants/credit-limit-increase.constants';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from 'containers/financing/views/credit-limit-increase/enums/credit-limit-increase.view.enumeration';
// Hooks
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
// Translations
import {
  translationsOtherSteps,
  translationsRejectOffer,
} from 'containers/financing/views/credit-limit-increase/translations/exit-modal-credit-limit-increase.component.translations';
import { step5Translations } from '../translations/step-5-credit-limit-increase.translations';
// Types
import { GetExitModalPropsType } from './types/get-exit-modal-props.type';
import { GetWizardStepFooterConfigurationPropsType } from './types/step-5-credit-limit-increase.configuration.type';
import { WizardStepFooterComponentType } from 'components/wizard-step-footer/types/wizard-step-footer.component.type';
import { CancelModalType } from 'components/wizard-step-footer/types/sub-types/cancel-modal.type';

const getExitModalConfig = ({
  isLoadingRejectOfferRequest,
  currentStep,
  disabledButtonsCancelModal,
  isRejectModal,
  showModal,
  setShowModal,
  cancelModalButtonClick,
  cancelModalLinkClick,
}: GetExitModalPropsType): CancelModalType => ({
  cancelUrl,
  disabledButtons: disabledButtonsCancelModal || isLoadingRejectOfferRequest,
  showModal,
  setShowModal,
  clicks: {
    buttonClick: cancelModalButtonClick,
    linkClick: cancelModalLinkClick,
  },
  translations: isRejectModal
    ? {
        title: translationsRejectOffer.TITLE,
        description: translationsRejectOffer.DESCRIPTION,
        valuesDescription: { br: <br /> },
        link: translationsRejectOffer.LINK_TEXT,
        button: translationsRejectOffer.PRIMARY_BUTTON_TEXT,
      }
    : {
        title: translationsOtherSteps.TITLE,
        description: translationsOtherSteps.DESCRIPTION,
        valuesDescription: {
          days:
            currentStep > 4 ? constants.EXIT_MODAL_DAYS.FIVE : constants.EXIT_MODAL_DAYS.FIFTEEN,
        },
        link: translationsOtherSteps.LINK_TEXT,
        button: translationsOtherSteps.PRIMARY_BUTTON_TEXT,
      },
});

const isRightButtonInNewBetterOfferDisabled = (
  isLoadingGoToSignContractRequest?: boolean,
  nextButtonDisabled?: boolean
): boolean => Boolean(isLoadingGoToSignContractRequest) || Boolean(nextButtonDisabled);

// eslint-disable-next-line complexity
export const getWizardStepFooterConfiguration = ({
  status,
  nextButtonDisabled,
  setShowModal,
  setIsRejectModal,
  formatMessage,
  handleStep5GoNext,
  handleGoToFinancing,
  handleEvaluateNewOffer,
  ...rest
}: GetWizardStepFooterConfigurationPropsType): WizardStepFooterComponentType => {
  const { step5, wizardStatus } = CreditLimitIncreaseContextConsumerHook();
  const { isLoadingRejectOfferRequest, isLoadingGoToSignContractRequest } = step5;
  const currentStep = wizardStatus.currentStep;

  switch (status) {
    case CreditLimitIncreaseStepsEnumeration.APPROVAL:
      return {
        cancelModal: getExitModalConfig({ ...rest, currentStep, setShowModal }),
        isFooterDisabled: step5.isLoadingNextRequest,
        rightButtonConfiguration: {
          onClick: handleStep5GoNext,
          loading: step5.isLoadingNextRequest,
        },
      };
    case CreditLimitIncreaseStepsEnumeration.END_NAVIGATION:
    case CreditLimitIncreaseStepsEnumeration.REJECT_M2:
      return {
        rightButtonConfiguration: {
          children: formatMessage({ id: step5Translations.EXIT_THE_PROCESS }),
          onClick: handleGoToFinancing,
        },
        hasDefaultLeftLink: false,
        hasDefaultRightButton: false,
      };
    case CreditLimitIncreaseStepsEnumeration.EVALUATE_NEW_BETTER_OFFER:
      return {
        cancelModal: getExitModalConfig({
          ...rest,
          currentStep,
          isLoadingRejectOfferRequest,
          setShowModal,
        }),
        hasDefaultRightButton: false,
        rightButtonConfiguration: {
          disabled: isRightButtonInNewBetterOfferDisabled(
            isLoadingGoToSignContractRequest,
            nextButtonDisabled
          ),
          children: formatMessage({ id: step5Translations.GO_TO_SIGN_CONTRACT }),
          loading: isLoadingGoToSignContractRequest,
          onClick: () => handleEvaluateNewOffer(true),
        },
        leftButtonConfiguration: {
          assetConfiguration: undefined,
          children: formatMessage({ id: step5Translations.REJECT_OFFER }),
          onClick: () => {
            setIsRejectModal(true);
            setShowModal(true);
          },
        },
        isFooterDisabled: isLoadingGoToSignContractRequest,
      };
    case CreditLimitIncreaseStepsEnumeration.RISK_REVIEW:
    case CreditLimitIncreaseStepsEnumeration.PENDING:
      return {
        rightLinkConfiguration: {
          onClick: handleGoToFinancing,
          children: formatMessage({ id: step5Translations.GO_TO_FINANCING }),
        },
        hasDefaultLeftLink: false,
        hasDefaultRightButton: false,
      };
    default:
      return {};
  }
};
